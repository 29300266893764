import { Loader } from "google-maps";

const init = async () => {
    const loader = new Loader("AIzaSyDQGgvlJ8n8mq8Ezt6NIaqLUf4om4-4UOI");
    const google = await loader.load();
    const grc = { lat: 52.2408158, lng: 20.911593 };

    const { AdvancedMarkerView } = await google.maps.importLibrary("marker");

    const map = new google.maps.Map(document.getElementById("map"), {
        center: grc,
        zoom: 15
    });

    const marker = new google.maps.Marker({
        position: grc,
        map: map,
        title: "Górczewska 200, Warszawa"
    });
};

init();

